import styles from "./styles.module.scss";

export const Home = () => {
  return (
    <div className={styles.Home}>
      <div className={styles.titleContent}>
        <div className={styles.title}>Hey, I’m Graham</div>
        <div className={styles.subtitle}>
          Normally there is a lot more to see here, but frankly, life's been a bit hectic lately. In the last 3 weeks I stopped growing the business I built for 12 months, started a new startup, got funded, and moved to sf (i can't quite tell you why yet). I may not have time to code my site, but I'm always down for a good chat, hmu. 
        </div>
      </div>
    </div>
  );
};
